.upload-container {
  position: relative;
}

.upload-button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  color: #333;
  border: 2px dotted grey;
  border-radius: 4px;
  cursor: pointer;
  border-spacing: 50px;
  height: 160px;
  width: 100px;
  position: relative;
}

.upload-file {
  display: flex;
}

.upload-file div {
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  color: #333;
  border: 2px solid grey;
}

.upload-button > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#file-name {
  display: block;
  margin-top: 10px;
}

/* Hide the default file input */
.inputFile {
  display: none;
}
